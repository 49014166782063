import { Loader } from '@googlemaps/js-api-loader'
import type Coordinates from '~/types/Coordinates'
import type Company from '~/types/Company'

export async function loadGoogleMaps(): Promise<any> {
  const loader = new Loader({
    apiKey: 'AIzaSyDVYoGJ1NaKgqdrfVviGXh7rqVhY1uZe0g',
    version: 'weekly',
    libraries: ['places'],
  })

  return await loader.load()
}

export async function getCurrentPosition(): Promise<Coordinates | undefined> {
  if (!navigator.geolocation) {
    return
  }

  const position: any = await new Promise((res, rej) => {
    navigator.geolocation.getCurrentPosition(res, rej)
  })

  return {
    lat: position.coords.latitude,
    lng: position.coords.longitude,
  }
}

export async function getCityAndStateByCoordinates(
  google: any,
  position: Coordinates
): Promise<string> {
  let city = ''
  let state = ''
  let country = ''

  const geocoder = new google.maps.Geocoder()

  const { results } = await geocoder.geocode({ latLng: position })

  for (let i = 0; i < results[0].address_components.length; i++) {
    for (let b = 0; b < results[0].address_components[i].types.length; b++) {
      if (results[0].address_components[i].types[b] == 'locality') {
        city = results[0].address_components[i].long_name
        break
      }

      if (
        results[0].address_components[i].types[b] ==
        'administrative_area_level_1'
      ) {
        state = results[0].address_components[i].long_name
        break
      }

      if (results[0].address_components[i].types[b] == 'country') {
        country = results[0].address_components[i].long_name
        break
      }
    }
  }

  return `${city}, ${state}, ${country}`
}

export async function getCoordinatesByAddress(
  google: any,
  address: string
): Promise<Coordinates> {
  const geocoder = new google.maps.Geocoder()

  const { results } = await geocoder.geocode({ address: address })

  return {
    lat: results[0].geometry.location.lat(),
    lng: results[0].geometry.location.lng(),
  }
}

export function getAddressFromPlace(place: any): string {
  let location = ''
  let city = ''
  let state = ''
  let country = ''

  for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
    const componentType = component.types[0]

    switch (componentType) {
      case 'locality':
        city = component.long_name
        break

      case 'administrative_area_level_1': {
        state = component.long_name
        break
      }

      case 'country':
        country = component.short_name
        break
    }
  }

  if (city && city.trim()) {
    location = city.trim()
  }
  if (state && state.trim()) {
    location = location + ', ' + state.trim()
  }
  if (country && country.trim()) {
    location = location + ', ' + country.trim()
  }

  return location
}

export async function getDistanceBetweenAddresses(
  google: any,
  origins: string[],
  destinations: string[]
): Promise<string[]> {
  const distanceMatrixService = new google.maps.DistanceMatrixService()

  const response = await distanceMatrixService.getDistanceMatrix({
    origins: origins,
    destinations: destinations,
    travelMode: google.maps.TravelMode.DRIVING,
    unitSystem: google.maps.UnitSystem.IMPERIAL,
    avoidHighways: false,
    avoidTolls: false,
  })

  return response.rows[0].elements.map((element: any) => {
    return element.distance && element.distance.text
      ? element.distance.text
      : ''
  })
}

export function addMarkerToMap(
  google: any,
  map: google.maps.Map,
  coordinates: Coordinates,
  company: Company
): google.maps.Marker {
  return new google.maps.Marker({
    position: coordinates,
    map,
    title: company.name,
  })
}

export function createInfoWindow(
  google: any,
  company: Company
): google.maps.InfoWindow {
  let content = `<div><h5>${company.name}</h5>`
  content += `<img style="height: 30px;" src='${company.logo}'>`
  content += `</div><div id='bodyContent'>${company.categories.join(',')}</div>`

  return new google.maps.InfoWindow({
    content,
    maxWidth: 200,
    ariaLabel: company.name,
  })
}
