import type Category from '~/types/Category'

const categories: Category[] = [
  { name: "ATV", code: 1 },
  { name: "UTV", code: 2 },
  { name: "Snowmobile", code: 3 },
  { name: "Boat", code: 4 },
  { name: "Cabin", code: 5 },
  { name: "Houseboat", code: 6 },
  { name: "Bicycle", code: 7 },
  { name: "Paddleboard", code: 8 },
  { name: "Fishing Guide", code: 9 },
  { name: "Event", code: 10 },
  { name: "Trip", code: 11 },
  { name: "Fish House", code: 12 },
  { name: "Canoe", code: 13 },
  { name: "Kayak", code: 14 },
  { name: "Room", code: 15 },
  { name: "Snowshoes", code: 16 },
  { name: "Skis", code: 17 },
  { name: "Inner Tube", code: 18 },
  { name: "Tent Campsite", code: 19 },
  { name: "RV Campsite", code: 20 },
  { name: "Jet Ski", code: 21 },
];

export function getCategories(): Category[] {
  const list = [...categories]
  list.sort((a, b) => {
    if (a.name < b.name) {
      return -1
    }

    if (a.name > b.name) {
      return 1
    }

    return 0
  })

  list.unshift({ name: 'Any', code: 0 })

  return list
}

