
<template>
  <v-text-field
    :model-value="modelValue"
    variant="outlined"
    single-line
    density="compact"
    hide-details
    base-color="secondary"
    bg-color="white"
    color="secondary"
    placeholder="Choose a location"
  ></v-text-field>
</template>


<script lang="ts" setup>
import { onMounted, useAttrs } from 'vue'
import {
  loadGoogleMaps,
  getCurrentPosition,
  getCityAndStateByCoordinates,
  getAddressFromPlace,
} from '@/services/GeoService'
import type Coordinates from '~/types/Coordinates'

const props = defineProps<{
  modelValue: string
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
}>()

const attrs = useAttrs()

onMounted(async () => {
  const google = await loadGoogleMaps()

  // Autocomplete
  const locationAutocomplete = new google.maps.places.Autocomplete(
    document.getElementById(attrs.id as string) as HTMLInputElement,
    {
      types: ['(cities)'],
      componentRestrictions: { country: ['us', 'ca'] },
    }
  )

  locationAutocomplete.addListener('place_changed', () => {
    const place = locationAutocomplete.getPlace()

    if (!place.geometry) {
      return
    }

    const address = getAddressFromPlace(place)

    emit('update:modelValue', address)
  })

  // Get current location
  if (!props.modelValue) {
    const coordinates: Coordinates | undefined = await getCurrentPosition()

    if (coordinates) {
      const address: string = await getCityAndStateByCoordinates(
        google,
        coordinates
      )

      emit('update:modelValue', address)
    }
  }
})
</script>
