<template>
  <v-select :model-value="modelValue" single-line :items="categories" item-title="name" item-value="name"
    variant="outlined" multiple chips closable-chips hide-details density="compact" placeholder="Search by category"
    @update:modelValue="emit('update:modelValue', $event)"></v-select>
</template>

<script lang="ts" setup>
import { getCategories } from '@/services/CategoryService'
let route = useRoute();
let property = usePropertyStore().currentProperty;
defineProps<{
  modelValue: string[]
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: string[]): void
}>()

const categories = route.params.propertyguid ? property.categories : getCategories()
</script>
